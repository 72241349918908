@import "src/styles/screens";

.background {
  position: fixed;
  top: 70px;

  //background-position: center;
  //background-repeat: no-repeat;
  //background-size: cover;

  padding: 20px 25px;
  width: 100vw;
  height: 100vh;

  background-color: white;

  //background-color: rgba(255, 255, 255, 0.7);/**/
  //backdrop-filter: blur(15px);

  @media screen and (min-width: $desktopS) {
    display: none;
  }

  .topControl {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 10px 0;

    @media screen and (min-width: $mobileM) {
      padding: 20px 0;
    }

    .downloadLink {
      display: flex;
      align-items: center;
      column-gap: 5px;
      color: #E469D1;

      text-decoration: none;
    }
  }

  .trackCoverWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      width: 220px;
      height: 220px;

      @media screen and (min-width: $mobileM) {
        width: 270px;
        height: 270px;
      }
    }
  }

  .trackInformation {
    display: flex;
    align-items: center;
    column-gap: 5px;

    font-size: 14px;
    line-height: 16px;

    padding: 20px 0;
  }

  .trackTitle {
    font-family: 'Magnolia Script', 'SansSerif';

    font-size: 24px;
    line-height: 32px;
  }

  .progressIndicator {
    position: relative;
    margin-top: 20px;

    background-color: #DDDDDD;

    height: 5px;
    width: 100%;

    .progressBar {
      background-color: #E469D1;

      height: 5px;

      position: absolute;
      left: 0;
      top: 0;

      z-index: 1000;
    }

    .thumb {
      background-color: #E469D1;
      border-radius: 3px;

      height: 18px;
      width: 5px;

      position: absolute;
      left: 0;
      top: -6px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: fixed;
    bottom: 38px;
    left: 25px;
    width: calc(100vw - 50px);
  }
}
