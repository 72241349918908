@import "src/styles/screens";

.player {
  display: flex;
  align-items: center;

  position: fixed;
  left: 0;
  bottom: 0;

  background-color: white;

  height: 65px;
  width: 100vw;
  z-index: 100000;

  padding: 0 50px;

  @media screen and (min-width: $desktopS) {
    padding: 0 160px;
  }

  > * {
    user-select: none;
  }

  .progressBar {
    background-color: #E469D1;

    height: 5px;

    position: absolute;
    left: 0;
    top: 0;

    z-index: 1000;
  }

  .progressBarLayout {
    cursor: pointer;
    background-color: #DDDDDD;

    height: 5px;

    position: absolute;
    left: 0;
    top: -5px;
    width: 100%;

    .thumb {
      display: none;

      background-color: #E469D1;
      border-radius: 3px;

      height: 18px;
      width: 5px;

      position: absolute;
      left: 0;
      top: -6px;
    }

    &:hover {
      .thumb {
        display: unset;
      }
    }
  }

  .controls {
    display: flex;
    align-items: center;

    > *:first-child {
      display: none;
    }

    > *:nth-child(2) {
      position: absolute;
      right: 26px;
    }

    > *:nth-child(3) {
      display: none;
    }

    @media screen and (min-width: $laptop) {
      column-gap: 70px;
      margin-right: 116px;

      > *:first-child {
        display: unset;
      }

      > *:nth-child(2) {
        position: unset;
        right: unset;
      }

      > *:nth-child(3) {
        display: unset;
      }
    }
  }

  .trackInfoWrapper {
    display: flex;
    align-items: center;

    @media screen and (min-width: $laptop) {
      column-gap: 20px;
    }

    .cover {
      display: flex;
      width: 40px;
      height: 40px;

      margin-left: 26px;
      margin-right: 10px;

      @media screen and (min-width: $laptop) {
        width: 50px;
        height: 50px;
      }
    }

    .trackInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 10px;

      .title {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;

        user-select: text;
      }

      .artist {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        user-select: text;
      }
    }
  }

  .actions {
    display: none;
    align-items: center;

    @media screen and (min-width: $laptop) {
      display: flex;

      position: absolute;
      right: 175px;
      column-gap: 80px;
    }

    .downloadButtonWrapper {
      display: flex;
      align-items: center;
      column-gap: 7px;

      text-decoration: none;

      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #E469D1;
    }

    .closeButtonWrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;
    }
  }
}

.buttonWrapper {
  display: flex;
  cursor: pointer;
}
